import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import globalStateReducer from './slices/globalState'
import usersReducer from './slices/user'
import machineReducer from './slices/machine'
import ordersTaskReducer from './slices/ordersTask'

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const rootReducer = combineReducers({
  user: usersReducer,
  machine: machineReducer,
  ordersTask: ordersTaskReducer,
  globalState: globalStateReducer,
});

export { rootPersistConfig, rootReducer };
