import { Link as RouterLink } from 'react-router-dom';
// material
import { styled,useTheme } from '@mui/material/styles';
import { Outlet } from 'react-router-dom';
// components
import Logo from '../components/CentralLogo';
import ConnectionLost from 'components/ConnectionLost';
import { RootState } from 'redux/store';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  justifyItems: 'center',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(1),
  justifyContent: 'center',
}));

// ----------------------------------------------------------------------

const RootStyle = styled('div')({
  display: 'flex',
  height: '100%',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  height: '100%',
  paddingTop: theme.spacing(0),
  paddingBottom: theme.spacing(0),
  [theme.breakpoints.up('lg')]: {
    paddingTop: theme.spacing(0),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

export default function ProductionLayout() {
  const theme = useTheme();

  return (
    <RootStyle>
      <HeaderStyle>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
      </HeaderStyle>
      <MainStyle
        sx={{
          transition: theme.transitions.create('margin', {
            duration: theme.transitions.duration.complex
          })
        }}
      >
        <Outlet />
        <ConnectionLost />
      </MainStyle>
    </RootStyle>
  );
}
