import { useRef, useState } from 'react';
import AppsIcon from '@mui/icons-material/Apps';
// material
import { alpha } from '@mui/material/styles';
import { Avatar, Button, Box, Divider, MenuItem, Typography, Tooltip } from '@mui/material';
// components
import { MIconButton } from '../../components/@material-extend';
import MenuPopover from '../../components/MenuPopover';
import DeleteIcon from '@mui/icons-material/Delete';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import { Stack } from '@mui/system';
import BoltIcon from '@mui/icons-material/Bolt';
import LockClockIcon from '@mui/icons-material/LockClock';
import PersonIcon from '@mui/icons-material/Person';
import { useNavigate } from 'react-router';
import { Badge } from '@mui/material';

// ----------------------------------------------------------------------

export default function AppPopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          ml: 'auto',
          mr: 'auto',
          mb: 2,
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              borderRadius: '50%',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <Badge badgeContent={10} color="error">
          <AppsIcon sx={{ fontSize: "2rem" }} />
        </Badge>
      </MIconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 400, height: 'auto' }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Stack>
            <Typography variant='h5' align='center' sx={{mb: 2}}>Installierte Apps</Typography>
          </Stack>
          <Stack direction="row" spacing={2} alignItems='flex-start' justifyContent={'space-between'}>
            <Stack>
              <Tooltip title="Hallo" placement="top-start">
                <>
                  <Badge badgeContent={10} color="error">
                    <Button variant='outlined' aria-label="delete" size="large" sx={{ width: 40, height: 60 }}  onClick={() => navigate("/boards/energy")}>
                      <BoltIcon fontSize="large" />
                    </Button>
                  </Badge>
                  <Typography textAlign={'center'}>Energizer</Typography>
                </>
              </Tooltip>
            </Stack>


            <Stack>
              <Button variant='outlined' aria-label="delete" size="large" sx={{ width: 40, height: 60 }} onClick={() => navigate("/times")}>
                <LockClockIcon fontSize="large" />
              </Button>
              <Typography textAlign={'center'}>Easy <br /> Access</Typography>
            </Stack>

            <Stack>
              <Button variant='outlined' aria-label="delete" size="large" sx={{ width: 40, height: 60 }} onClick={() => navigate("/plan")}>
                <ViewQuiltIcon fontSize="large" />
              </Button>
              <Typography textAlign={'center'}>Planning</Typography>
            </Stack>

            <Stack>
              <Button variant='outlined' aria-label="delete" size="large" sx={{ width: 40, height: 60 }} onClick={() => navigate("/info/production")}>
                <PersonIcon fontSize="large" />
              </Button>
              <Typography textAlign={'center'}>MaLa</Typography>
            </Stack>
          </Stack>
        </Box>
        { false && <><Divider sx={{ my: 1 }} />
          <Box sx={{ p: 2, pt: 1.5 }}>
            <Button fullWidth color="inherit" variant="outlined" onClick={() => {}}>
              Mehr Apps entdecken
            </Button>
          </Box></>
        }
      </MenuPopover>
    </>
  );
}
