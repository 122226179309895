import { styled, Box, Typography, CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";

const BoxStyle = styled(Box)(({ theme }) => ({
  position: 'fixed',
  left: 0,
  top: 0,
  backdropFilter: 'blur(10px)',
  width: '100%',
  height: '100%',
  alignItems: 'center',
  textAlign: 'center',
  justifyContent: 'center'
}))

const ConnectionLost = () => {

  const { connectBoxLost } = useSelector((state: RootState) => state.globalState);

  useEffect(() => {
    console.log(connectBoxLost)
  }, [connectBoxLost])

  return <BoxStyle sx={{ left: connectBoxLost ? 0 : -10000 }}>
    <Typography sx={{ mt: 'calc(25% - 4rem)', fontSize: '8rem', fontWeight: 'bold' }}>Verbindungsverlust</Typography>
    <CircularProgress size={'4rem'} />
  </BoxStyle>
}

export default ConnectionLost;