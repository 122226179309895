import * as React from 'react';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import LightboxModal from 'components/LightboxModal';
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AddOrderModal from 'pages/orders/AddOrderModal';
import Fab from '@mui/material/Fab';
import ChipModal from 'components/ChipModal';

const actions = [
  { icon: <FileCopyIcon />, name: 'Fertigungsauftrag', action: 'order' },
  { icon: <DataUsageIcon />, name: 'Produktionsreport', action: 'report' },
  { icon: <AccessTimeIcon />, name: 'Zeitbuchung', action: 'time' },
  { icon: <ShareLocationIcon />, name: 'Chip', action: undefined },
];

export default function SpeedDialTooltipOpen() {
  const [open, setOpen] = React.useState(false);
  const [addChip, setAddChip] = React.useState(false);
  const [action, setAction] = React.useState<string | undefined>()
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false)
  };

  const handleClick = (action: any) => {
    setOpen(false)
    setAction(action.action);
  }

  return (
    <Box sx={{ height: 336, zIndex: 999999, transform: 'translateZ(0px)', flexGrow: 1, position: 'fixed', bottom: 20, right: 20 }}>
      <Backdrop open={open} />
      <SpeedDial
        ariaLabel="Menü"
        sx={{ position: 'absolute', bottom: 16, right: 16 }}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={() => handleClick(action)}
          />
        ))}
      </SpeedDial>

      <Fab color="secondary" sx={{ position: 'fixed', bottom: 15, right: 80, height: 56, width: 56 }} onClick={() => setAddChip(true)}>
        <ShareLocationIcon />
      </Fab>

      { action && <AddOrderModal open={action == 'order'} onCallback={() => { setAction(undefined); setOpen(false) }} onSaved={() =>  { setAction(undefined); setOpen(false) }} /> }
      <ChipModal open={addChip} handleClose={() => setAddChip(false)} />
    </Box>
  );
}