import { noCase } from 'change-case';
import { random } from 'lodash';
// material
import { Box, Card, CardHeader, Typography, Stack } from '@mui/material';
// utils
import { fConsumption } from '../../utils/formatNumber';
import mockData from '../../utils/mock-data';
//
import Label from '../Label';
import Scrollbar from '../Scrollbar';
import moment from 'moment';
import { useNavigate } from 'react-router';

// ----------------------------------------------------------------------

type ItemProps = {
  id: string;
  name: string;
  machine: string | undefined;
  last_ping: any;
  current_consumption: number;
  consumption: number;
};

function ApplicationItem({ module }: { module: ItemProps }) {
  const { id, name, machine, last_ping, current_consumption } = module;

  const variantFromPing = (ping: string) => {
    if(ping == undefined) return 'error';

    const date1: Date = new Date();
    const date2: Date = new Date(ping);

    const timeDiff: number = Math.abs(date1.valueOf() - date2.valueOf());

    if(timeDiff < 1000 * 3600) return 'success';
    if(timeDiff < 1000 * 3600 * 24) return 'warning'

    return 'error'
  }

  const navigate = useNavigate()

  return (
    <Stack direction="row" alignItems="center" spacing={2} onClick={() => navigate(`/powerModules/${id}`)}>
      <Box
        sx={{
          width: 48,
          height: 48,
          flexShrink: 0,
          display: 'flex',
          borderRadius: 1.5,
          alignItems: 'center',
          justifyContent: 'center',
          bgcolor: 'background.neutral'
        }}
      >
      </Box>

      <Box sx={{ flexGrow: 1, minWidth: 160 }}>
        <Typography variant="subtitle2">{name}</Typography>
        <Stack direction="row" alignItems="center" sx={{ mt: 0.5, color: 'text.secondary' }}>
          <Label
            variant={'ghost'}
            color={variantFromPing(last_ping)}
          >
            { last_ping ? moment(last_ping).fromNow() : 'Kein Ping' }
          </Label>
        </Stack>
      </Box>

      <Stack alignItems="flex-end" sx={{ pr: 3 }}>
        <Typography variant="h5">{fConsumption(current_consumption)}</Typography>
      </Stack>
    </Stack>
  );
}

export default function Modules({ modules}: {modules: any[]}) {
  return (
    <Card>
      <CardHeader title="Energie Module" />
      <Scrollbar>
        <Stack spacing={3} sx={{ p: 3, pr: 0 }}>
          {modules.map((module) => (
            <ApplicationItem key={module.id} module={module} />
          ))}
        </Stack>
      </Scrollbar>
    </Card>
  );
}
