import { replace } from 'lodash';
import numeral from 'numeral';

// ----------------------------------------------------------------------

// load a locale
numeral.register('locale', 'de', {
  delimiters: {
      thousands: ' ',
      decimal: ','
  },
  abbreviations: {
      thousand: 'k',
      million: 'm',
      billion: 'b',
      trillion: 't'
  },
  ordinal : function (number) {
      return number === 1 ? 'er' : 'ème';
  },
  currency: {
      symbol: '€'
  }
});

export function fCurrency(number: string | number) {
  numeral.locale("de")
  return numeral(number).format(Number.isInteger(number) ? '0,0.00' : '0.00', (val: number) => Math.round(val)) + "€";
}

export function fPercent(number: number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number: string | number) {
  return numeral(number).format();
}

export function fShortenNumber(number: string | number) {
  return replace(numeral(number).format('0.00a'), '.00', '');
}

export function fData(number: string | number) {
  return numeral(number).format('0.0 b');
}

export function fConsumption(number: string | number) {
  return numeral(number).format(Number.isInteger(number) ? '0,0' : '0.00') + " kWh";
}
