// material
import { Popover, PopoverProps } from '@mui/material';

// ----------------------------------------------------------------------

export default function MenuPopover({ children, sx, ...other }: PopoverProps) {
  return (
    <Popover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      PaperProps={{
        sx: {
          mt: 1.5,
          ml: 12,
          overflow: 'inherit',
          boxShadow: (theme) => theme.customShadows.z20,
          border: (theme) => `solid 1px ${theme.palette.grey[500_8]}`,
          width: 400,
          ...sx
        }
      }}
      {...other}
    >
      {children}
    </Popover>
  );
}
