import {
  Container,
} from '@mui/material';
// hooks
import useSettings from '../../hooks/useSettings';
// @types
// components
import Page from '../../components/Page';

export default function Boards(){
  const { themeStretch } = useSettings();

    return <Page title="Boards">
        <Container maxWidth={themeStretch ? false : 'lg'}>

        </Container>
  </Page>
}