import { DialogTitle } from '@mui/material';
import axios from 'axios';
import { Urls } from 'utils/urls';
import { DialogAnimate } from '../../components/animate';
import OrderForm from './OrderForm';
import { useSnackbar } from "notistack";
import useAuth from 'hooks/useAuth';

interface AddOrderFormProps {
    open: boolean, 
    onSaved: VoidFunction, 
    onCallback: VoidFunction
}

export default function AddOrderModal({ open = false, 
                                        onSaved = () => {}, 
                                        onCallback = () => {} }: AddOrderFormProps){
    const selectedOrder: boolean = false
    const { enqueueSnackbar } = useSnackbar();

    const { headers } = useAuth()

    const save = async (attributes: any) => {
        try {
            await axios.post(Urls.ORDERS, attributes, headers)

            onSaved()
        } catch(error) {
            enqueueSnackbar("Fehler beim Report erstellen")
        }
    }

    return (
        <DialogAnimate open={open} onClose={() => {}}>
            <DialogTitle>{selectedOrder ? 'Bearbeiten' : 'Auftrag Hinzufügen'}</DialogTitle>
            <OrderForm onCancel={() => onCallback()} 
                      order={undefined} 
                      onSave={save}/>
        </DialogAnimate>
    )
}