// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/';

// ----------------------------------------------------------------------

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    
  },
  app: {
    root: path(ROOTS_DASHBOARD, ''),
  },

  boards: {
    root: path(ROOTS_DASHBOARD, 'boards')
  },

  user: {
    root: path(ROOTS_DASHBOARD, 'users')
  },

  times: {
    root: path(ROOTS_DASHBOARD, 'times')
  },

  power_consumptions: {
    root: path(ROOTS_DASHBOARD, 'power_consumptions')
  },

  powerModule: {
    root: path(ROOTS_DASHBOARD, 'powerModule')
  },

  connectBoxes: {
    root: path(ROOTS_DASHBOARD, 'connectBoxes')
  },

  connectBox: {
    root: path(ROOTS_DASHBOARD, 'connectBox')
  },

  timeModule: {
    root: path(ROOTS_DASHBOARD, 'timeModule')
  },

  timeModules: {
    root: path(ROOTS_DASHBOARD, 'timeModules')
  },

  machine: {
    root: path(ROOTS_DASHBOARD, 'machines')
  },

  qrcodes: {
    root: path(ROOTS_DASHBOARD, 'qrcodes')
  },

  settings: {
    root: path(ROOTS_DASHBOARD, 'settings')
  },

  locations: {
    root: path(ROOTS_DASHBOARD, 'locations')
  },

  reports: {
    root: path(ROOTS_DASHBOARD, 'reports')
  },

  planning: {
    root: path(ROOTS_DASHBOARD, 'plan'),
  },

  orders: {
    root: path(ROOTS_DASHBOARD, 'orders')
  },

  devices: {
    root: path(ROOTS_DASHBOARD, 'devices')
  },

  warehouse: {
    root: path(ROOTS_DASHBOARD, 'warehouse')
  },

  tasks: {
    root: path(ROOTS_DASHBOARD, 'tasks')
  }
};
