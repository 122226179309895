import { ButtonGroup, Stack } from '@mui/material';
import { Button } from '@mui/material';
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material';
import {
  Container,
} from '@mui/material';
import axios from 'axios';
import CurrentEnergyCosts from 'components/EnergyBoard/CurrentEnergyCosts';
import ModuleConsumption from 'components/EnergyBoard/ModuleConsumption';
import Modules from 'components/EnergyBoard/Modules';
import MonthlyConsumption from 'components/EnergyBoard/MonthlyConsumption';
import TotalEnergyConsumption from 'components/EnergyBoard/TotalEnergyConsumption';
import TotalEnergyCosts from 'components/EnergyBoard/TotalEnergyCosts';

// ----------------------------------------------------------------------

import Page from "components/Page";
import useAuth from 'hooks/useAuth';
import useSettings from "hooks/useSettings";
import { useEffect, useState } from 'react';
import { fConsumption, fCurrency } from 'utils/formatNumber';
import { fMonths } from 'utils/formatTime';
import { Urls } from 'utils/urls';

export default function EnergyBoard() {
  const { themeStretch } = useSettings();
  const { headers } = useAuth()
  const [date, setDate] = useState<Date>(new Date())
  const [data, setData] = useState<any>({ total_costs: 0, total_consumption: 0, current_price: 0, consumption: [], modules: [], month_consumption: [] })
  const [monthData, setMonthData] = useState<any[]>([])

  useEffect(() => {
    axios.get(`${Urls.BOARDS_ENERGY}?date=${date.toDateString()}`, headers).then((response) => {
      setData(response.data)
      setMonthData(formatMonthData(response.data))
    })
  }, [date])

  const StyledButton = styled(Button)(({ theme }) => ({
    width: '100%', 
    boxShadow: undefined,
    borderWidth: '3px !important',
    borderColor: 'rgba(22, 28, 36, 0.72) !important',
    backgroundColor: '#212B36',
    '&.active': {
      backgroundColor: theme.palette.primary.main
    }
  }))


  const formatMonthData = (data: any) => {
    var ret: string[] = []

    fMonths().map((month, index) => {
      if(index <= (new Date()).getMonth()){
        ret.push(`${data.month_consumption[index].costs.toFixed(0)} € | ${data.month_consumption[index].consumption.toFixed(0)} kWh`)
      } else {
        ret.push("0 kWh | 0€")
      }
    }) 

    return ret;
  }

  return <Page title="EnergyBoard">
    <Container maxWidth={themeStretch ? false : 'lg'}>
      <ButtonGroup variant="contained" sx={{ width: '100%', mb: 4, boxShadow: 'none', borderColor: '#212B36' }}>
        { fMonths().map((month, index) => <StyledButton color='primary' 
                                                  disabled={index > (new Date()).getMonth()} 
                                                  key={month} 
                                                  className={index == (date).getMonth() ? 'active' : undefined}
                                                  onClick={() => setDate(new Date(`${index + 1}/1/2023`))}>
                                                    <Stack>
                                                      <Typography variant={"h5"}>{month}</Typography>
                                                      { index <= (new Date()).getMonth() && <Typography fontSize={12} sx={{fontWeight: 'bold'}}>
                                                        { monthData[index] }
                                                      </Typography> }
                                                    </Stack>
                                          </StyledButton>) }
      </ButtonGroup>
      <Grid container spacing={5}>
        <Grid item xs={12} md={4}>
          <TotalEnergyConsumption totalEnergyConsumption={data.total_consumption} />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TotalEnergyCosts totalEnergyCosts={data.total_costs} />
        </Grid>

        <Grid item xs={12} sm={4}>
          <CurrentEnergyCosts currentCosts={data.current_price} />
        </Grid>
        
        <Grid item xs={12} sm={8}>
          <MonthlyConsumption consumption={data.consumption} />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Modules modules={data.modules} />
        </Grid>

        <Grid item xs={12}>
          <ModuleConsumption modules={data.modules} />
        </Grid>
      </Grid>  
    </Container>
  </Page>
}
