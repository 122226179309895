// material
import { Box, BoxProps, Tooltip } from '@mui/material';
import logo from '../assets/images/gray-connect.svg'

// ----------------------------------------------------------------------

export default function Logo({ sx }: BoxProps) {
  
  return (
    <Box sx={{ width: 55, height: 55, ...sx }}>
      <Tooltip title="Homepage" placement="right">
        <img src={logo} style={{ height: 55 }} alt='logo' />
      </Tooltip>
    </Box>
  );
}
