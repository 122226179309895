import { useEffect } from 'react'
import ActionCable from "actioncable";
// Redux
import { useDispatch, useSelector, RootState } from '../redux/store';
import { setCable } from '../redux/slices/globalState';
import { setIncomingOrdersTask } from '../redux/slices/ordersTask';
import useAuth from './useAuth';
import { useSnackbar } from 'notistack';

/**
 * ActionCable Hook
 */
const useActionCable = () => {
  const { cable } = useSelector((state: RootState) => state.globalState);
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const { user } = useAuth()

  const websocket = (token: string) => {
    return `${process.env.REACT_APP_SOCKET_PROTOCOL}://${process.env.REACT_APP_SERVER_URL}/api/v1/cable?token=` + token;
  }

  useEffect(() => {
    if(!user || !enqueueSnackbar) return

    if(!Boolean(cable)) {
        dispatch(setCable(ActionCable.createConsumer(websocket(user.id))))
    }
    
    if(cable && cable.subscriptions.subscriptions.length === 0) {
      cable.subscriptions.create({
        channel: "OrdersTasksChannel",
        id: user.id,
      }, 
      {
        received(data: any) {
          dispatch(setIncomingOrdersTask(data))
        },
        connected() {
          setCable(cable)
        },
        disconnected() {
          setCable(undefined)
        },
      });
    }

    return () => {
      if(cable){
        cable.disconnect()
      }
    }
  }, [cable, user, enqueueSnackbar])
}

export default useActionCable;