// routes
import AppRouter from './routes'
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';
import LoadingScreen, { ProgressBarStyle } from './components/LoadingScreen';
import NotistackProvider from 'components/NotistackProvider';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import useAuth from 'hooks/useAuth';
import { Provider } from '@rollbar/react';
import moment from 'moment';
import 'moment/locale/de'

// ----------------------------------------------------------------------

export default function App() {

  // Language is German
  moment.locale("de");

  const { isInitialized } = useAuth()

  const rollbarConfig = {
    accessToken: "ed9e41b857344f8c821273a5e09c87b2",
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: `${process.env.NODE_ENV || 'Development'}`,
      client: {
        javascript: {
          code_version: '1.0.0',
          source_map_enabled: true
        }
      }
    }
  };

  return (
    <Provider config={rollbarConfig}>
      <ThemeConfig>
        <ThemePrimaryColor>
          <NotistackProvider>
            <GlobalStyles />
            <ProgressBarStyle />
            <ScrollToTop />
            {isInitialized ?  <AppRouter /> : <LoadingScreen />}
          </NotistackProvider>
        </ThemePrimaryColor>
      </ThemeConfig>
    </Provider>
  );
}

// @ts-ignore
String.prototype.hashCode = function() {
  var hash = 0,
    i, chr;
  if (this.length === 0) return hash;
  for (i = 0; i < this.length; i++) {
    chr = this.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}
