import { createSlice } from '@reduxjs/toolkit';
import { OrdersTask } from '../../@types/ordersTask';

// ----------------------------------------------------------------------

const initialState: { currentOrdersTask: OrdersTask | undefined, ordersTasks: OrdersTask[], incomingOrdersTask: OrdersTask | undefined } = {
  incomingOrdersTask: undefined,
  currentOrdersTask: undefined,
  ordersTasks: [],
};

const slice = createSlice({
  name: 'ordersTask',
  initialState,
  reducers: {
    
    setOrdersTask(state, action) {
        state.currentOrdersTask = action.payload
    },

    setOrdersTasks(state, action) {
      state.ordersTasks = action.payload
    },

    setIncomingOrdersTask(state, action) {
      state.incomingOrdersTask = action.payload
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setOrdersTask, setOrdersTasks, setIncomingOrdersTask } = slice.actions;
