import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useEffect, useState } from 'react';
import { Urls } from 'utils/urls';
import useAuth from 'hooks/useAuth';
import axios from 'axios';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ContactlessIcon from '@mui/icons-material/Contactless';

// Battery Icons
import BatteryUnknownIcon from '@mui/icons-material/BatteryUnknown';
import Battery0BarIcon from '@mui/icons-material/Battery0Bar';
import Battery1BarIcon from '@mui/icons-material/Battery1Bar';
import Battery2BarIcon from '@mui/icons-material/Battery2Bar';
import Battery3BarIcon from '@mui/icons-material/Battery3Bar';
import Battery4BarIcon from '@mui/icons-material/Battery4Bar';
import Battery5BarIcon from '@mui/icons-material/Battery5Bar';
import Battery6BarIcon from '@mui/icons-material/Battery6Bar';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';

// Connectivity
import NetworkWifi1BarIcon from '@mui/icons-material/NetworkWifi1Bar';
import NetworkWifi2BarIcon from '@mui/icons-material/NetworkWifi2Bar';
import NetworkWifi3BarIcon from '@mui/icons-material/NetworkWifi3Bar';
import NetworkWifiIcon from '@mui/icons-material/NetworkWifi';

import SignalWifi0BarIcon from '@mui/icons-material/SignalWifi0Bar';



import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { LoadingButton } from '@mui/lab';
import { Button, DialogContent, DialogTitle, Paper } from '@mui/material';
import moment from 'moment';
import { DialogAnimate } from './animate';
import { Dialog } from '@mui/material';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const ChipModal = ({ open, handleClose }: { open: boolean, handleClose: any }) => {
    const [beacons, setBeacons] = useState<any>([])
    const [newBeacons, setNewBeacons] = useState<any>([])
    const [users, setUsers] = useState<any[]>([])
    const [beacon, setBeacon] = useState<any>()

    const [timer, setTimer] = useState<any>()
    const [ready, setReady] = useState<boolean>(false)
    
    const { headers } = useAuth()

    const renderBatteryLevel = (beacon: any) => {
      if(beacon.battery_level >= 100) return <BatteryFullIcon color='success' />
      if(beacon.battery_level >= 100 - (1 * 12.5)) return <BatteryFullIcon color='success' />
      if(beacon.battery_level >= 100 - (2 * 12.5)) return <Battery6BarIcon color='success' />
      if(beacon.battery_level >= 100 - (3 * 12.5)) return <Battery5BarIcon color='success' />
      if(beacon.battery_level >= 100 - (4 * 12.5)) return <Battery4BarIcon color='success' />
      if(beacon.battery_level >= 100 - (5 * 12.5)) return <Battery3BarIcon color='warning' />
      if(beacon.battery_level >= 100 - (6 * 12.5)) return <Battery2BarIcon color='warning' />
      if(beacon.battery_level >= 100 - (7 * 12.5)) return <Battery1BarIcon color='error' />
      if(beacon.battery_level >= 100 - (8 * 12.5)) return <BatteryUnknownIcon />
    }

    const renderConnectivity = (beacon: any) => {
      if(beacon.rssi == 0) return <SignalWifi0BarIcon />
      if(beacon.rssi < 50) return <NetworkWifiIcon />
      if(beacon.rssi < 70) return <NetworkWifi1BarIcon />
      if(beacon.rssi < 90) return <NetworkWifi2BarIcon />
      if(beacon.rssi < 110) return <NetworkWifi3BarIcon /> 
    }

    const formatRSSI = (beacon: any) => {
      if(beacon.rssi == 0) return "0%"

      return ((1 - (beacon.rssi / (110 - 20) )) * 100).toFixed(0) + "%"
    } 

    useEffect(() => {
      
      if(open) {
        axios.get(Urls.USERS, headers).then(response => setUsers(response.data))
        reloadBeacons()
      } else {
        clearTimeout(timer)
      }

      return () => {
        clearTimeout(timer)
      }
    }, [open])

    const reloadBeacons = () => {
      clearTimeout(timer)

      if(!open) return

      axios.get(Urls.BEACONS, headers).then(response => { 
        setBeacons(response.data.beacons); 
        setNewBeacons(response.data.new_beacons);
        setReady(true) 
      })

      setTimer(setTimeout(() => {
        reloadBeacons();
      }, 5000))
    }

    function ChildModal({ users, beacon, open, handleClose = () => {} }: { users: any[], beacon: any, open: boolean, handleClose: any }) {
      const handleChange = (event: SelectChangeEvent) => {
        setUserId(event.target.value as string);
      };

      const [userId, setUserId] = useState<any>()

      const [isSubmitting, setIsSubmitting] = useState<boolean>(false) 

      const onSave = async() => {
        try {
          setIsSubmitting(true)
          await axios.post(Urls.BEACONS, { address: beacon.address, user_id: userId }, headers)
          handleClose()
        } catch(e) {
          setIsSubmitting(false)
          handleClose()
        }
      }

      return (
        <React.Fragment>
          <Modal
            hideBackdrop
            open={open}
            onClose={handleClose}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <Box sx={{ ...style, width: 300 }}>
              <h2 id="child-modal-title">{ beacon?.address }</h2>
              <FormControl fullWidth sx={{mb: 2}}>
                <InputLabel id="user">Mitarbeiter</InputLabel>
                <Select labelId="user"
                        id="demo-simple-select"
                        value={userId}
                        label="user"
                        onChange={handleChange} >
                  { users.map((user: any) => <MenuItem key={user.id} value={user.id}>{ user.full_name }</MenuItem>) }
                </Select>
              </FormControl>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
                loadingIndicator="Speichern..."
                onClick={onSave}
              >
                Speichern
              </LoadingButton>
              <Button onClick={handleClose}>Abbrechen</Button>
            </Box>
          </Modal>
        </React.Fragment>
      );
    }

    const NewBeaconRow = ({beacon}: any) => {
      return <ListItem disablePadding id={beacon.address}>
                <ListItemButton onClick={() => setBeacon(beacon)}>
                    <ListItemIcon>
                        <ContactlessIcon />
                    </ListItemIcon>
                    <ListItemText primary={ beacon.address } />
                </ListItemButton>
            </ListItem>
    } 

    const BeaconRow = ({ beacon }: any) => {
      return <ListItem disablePadding id={beacon.address}>
      <ListItemButton onClick={() => setBeacon(beacon)}>
          <ListItemIcon>
            { renderBatteryLevel(beacon) }
          </ListItemIcon>
          <ListItemIcon>
            { renderConnectivity(beacon) }
          </ListItemIcon>
          <ListItemText primary={ `${formatRSSI(beacon)} | ${beacon.name} | ${beacon.ping ? moment(beacon.ping).fromNow() : 'Kein Ping'}` } />
      </ListItemButton>
  </ListItem>
    }

    return <Dialog open={ready && open} onClose={handleClose}
                  PaperComponent={(props: any) => (
                    <Box
                      sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <Box onClick={handleClose} sx={{ width: '100%', height: '100%', position: 'fixed' }} />
                      <Paper {...props}>{props.children}</Paper>
                    </Box>
                  )}
    
    >
      <DialogContent sx={{ width: '100%'}} >
            <Typography id="modal-modal-title" variant="h6" component="h2">
                Neue Chips
            </Typography>
            <List
              sx={{ width: '100%', bgcolor: 'background.paper' }}
              aria-label="contacts"
              >
              { newBeacons.map((beacon: any) => <BeaconRow beacon={beacon} />) }

              { newBeacons.length === 0 && <ListItem>
                      <ListItemIcon>
                          <ContactlessIcon />
                      </ListItemIcon>
                      <ListItemText primary='Kein Chip gefunden' />
                  </ListItem>
              
              }
              </List>

            <Typography id="modal-modal-title" variant="h6" component="h2">
                Registrierte Chips
            </Typography>
            <List
              sx={{ width: '100%', bgcolor: 'background.paper' }}
              aria-label="contacts"
              >
              { beacons.map((beacon: any) => <BeaconRow beacon={beacon}/>) }
              </List>
              { beacon && <ChildModal users={users} beacon={beacon} open={!!beacon} handleClose={() => {setBeacon(undefined); reloadBeacons()}} /> }
              </DialogContent>
        </Dialog>
}

export default ChipModal;