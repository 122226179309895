import { merge } from 'lodash';
import { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
// material
import { Card, CardHeader, Box, TextField } from '@mui/material';
//
import { BaseOptionChart } from '../charts'
import moment from 'moment';

// ----------------------------------------------------------------------

export default function MonthlyConsumption({ consumption }: { consumption: any[] }) {
  const prepareData = (consumption: any[]) => {
    const data: any[] = [{ name: "max kWh", data: [] }, { name: "&Oslash; kWh", data: []}]

    consumption.map((c: any) => {
      data[0].data.push(c.max_consumption)
      data[1].data.push(c.consumption)
    })

    return data;
  }

  const chartOptions = merge(BaseOptionChart(), {
    xaxis: {
      categories: consumption.map((data: any) => moment(data.date).format("DD"))
    },
    yaxis: { 
      labels: { show: true, formatter: (value: any) => `${Math.round(value)}`,
        style: {
          fontSize: '1rem',
        }, }, 
      type: 'string'
    },
  });

  return (
    <Card>
      <CardHeader
        title="Gesamtverbrauch in kWh"
        subheader=""
      />

      <ReactApexChart type="line" 
                      series={prepareData(consumption)} 
                      options={chartOptions}
                      height={364} />
    </Card>
  );
}
