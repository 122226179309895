import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Card, Stack, Link, Container, Typography } from '@mui/material';
// routes
import { PATH_AUTH } from '../../routes-app/paths';
// hooks
import useAuth from '../../hooks/useAuth';
// layouts
import AuthLayout from '../../layouts/AuthLayout';
// components
import Page from '../../components/Page';
import { MHidden } from '../../components/@material-extend';
import { LoginForm } from '../../components/authentication/login';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
  
  return (
    <RootStyle title="Login">
      <AuthLayout>
      </AuthLayout>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h4" gutterBottom>
                { Boolean(process.env.REACT_APP_DEMO) ? 'Demo Login' : 'Login' }
              </Typography>
              { Boolean(process.env.REACT_APP_DEMO) ? <Typography sx={{ color: 'text.secondary' }}>Das ist der Gray Connect Demo Account. Klicken Sie sich gerne rum. Sie können nichts kaputt machen 😉</Typography> : <Typography sx={{ color: 'text.secondary' }}>Trage deine Zugangsdaten ein</Typography> }
            </Box>
          </Stack>

          <LoginForm />

        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
