// material
import { Box, BoxProps, Tooltip } from '@mui/material';
import logo from '../assets/images/Graycon_Logo_white.png'

// ----------------------------------------------------------------------

export default function CentralLogo({ sx }: BoxProps) {
  
  return (
    <Box sx={{ ml: 'auto', mt: 2, height: '1rem', ...sx }}>
      <img src={logo} style={{ height: '1rem' }} alt='logo' />
    </Box>
  );
}
