import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { Box, Card, CardHeader } from '@mui/material';
// utils

//
import { fNumber } from 'utils/formatNumber';
import { BaseOptionChart } from 'components/charts';

// ----------------------------------------------------------------------

export default function ModuleConsumption({ modules}: { modules: any[] }) {
  const chartOptions = merge(BaseOptionChart(), {
    tooltip: {
      marker: { show: false },
      y: {
        formatter: (seriesName: string) => `${fNumber(seriesName)} kWh`,
        title: {
          formatter: () => ''
        }
      }
    },
    plotOptions: {
      bar: { horizontal: true, barHeight: '28%', borderRadius: 2 }
    },
    xaxis: {
      categories: modules.map((module: any) => module.name)
    }
  });

  return (
    <Card>
      <CardHeader title="Energie Verbrauch nach Modulen" />
      <Box sx={{ mx: 3 }} dir="ltr">
        <ReactApexChart type="bar" series={[{ data: modules.map((module) => module.consumption) }]} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
}
