const SERVER_URL = `${process.env.REACT_APP_WEB_PROTOCOL}://${process.env.REACT_APP_SERVER_URL}/api/v1/`

export const Urls = {
    SERVER_URL: SERVER_URL,
    SIGN_IN: `${SERVER_URL}login`,
    SIGN_UP: `${SERVER_URL}sign_up`,
    ACCOUNT: `${SERVER_URL}account`,
    BEACONS: `${SERVER_URL}beacons`,
    USERS: `${SERVER_URL}users`,
    UNITS: `${SERVER_URL}units`,
    TASKS: `${SERVER_URL}tasks`,
    TIMES: `${SERVER_URL}times`,
    ORDERS: `${SERVER_URL}orders`,
    MACHINES: `${SERVER_URL}machines`,
    MACHINES_STATISTIC: `${SERVER_URL}machines/statistics`,
    BOOKINGS: `${SERVER_URL}bookings`,
    LOCATIONS: `${SERVER_URL}locations`,
    STAFF_TIMES: `${SERVER_URL}staff_times`,
    ORDERS_TASKS: `${SERVER_URL}orders_tasks`,
    TIME_MODULES: `${SERVER_URL}time_modules`,
    BOARDS_ENERGY: `${SERVER_URL}boards/energy`,
    CONNECT_BOXES: `${SERVER_URL}connect_boxes`,
    POWER_MODULES: `${SERVER_URL}power_modules`,
    ORDER_REPORTS: `${SERVER_URL}order_reports`,
    MOBILE_DEVICES: `${SERVER_URL}mobile_devices`,
    RESOURCE_COSTS: `${SERVER_URL}resource_costs`,
    BOARDS_PRODUCTION: `${SERVER_URL}boards/production`,
    REGISTERED_DEVICES: `${SERVER_URL}registered_devices`,
    CONSUMPTION_MEASUREMENTS: (id: string) => `${SERVER_URL}power_modules/${id}/consumption_measurements`
}