import * as Yup from 'yup';
import { useState } from 'react';
import { merge } from 'lodash';
import { useSnackbar } from 'notistack';
import { useFormik, Form, FormikProvider } from 'formik';
import {
  Box,
  Button,
  TextField,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useAuth from 'hooks/useAuth';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}
// ----------------------------------------------------------------------

const getInitialValues = (order: any) => {
  // eslint-disable-next-line no-underscore-dangle
  const _order = {
    title: '',
    description: '',
  };

  if (order) {
    return merge({}, _order, order);
  }

  return _order;
};

// ----------------------------------------------------------------------

type OrderFormProps = {
  order: any;
  onSave: any;
  onCancel: VoidFunction;
};

export default function OrderForm({ order, onCancel, onSave }: OrderFormProps) {
  const [dateOfDelivery, setDateOfDelivery] = useState<any>()
  const { enqueueSnackbar } = useSnackbar();
  
  const OrderSchema = Yup.object().shape({});

  const formik = useFormik({
    initialValues: getInitialValues(order),
    validationSchema: OrderSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      try {
        const newOrder = {
          name: values.name,
          size: values.size,
          budget: values.budget,
          date_of_delivery: dateOfDelivery,
          business_partner: values.business_partner,
        };
        if (order?.id) {
          enqueueSnackbar('Auftrag geändert', { variant: 'success' });
        } else {
          enqueueSnackbar('Auftrag angelegt', { variant: 'success' });
          onSave(newOrder)
        }
        resetForm();
        onCancel();
        setSubmitting(false);
      } catch (error) {
        console.error(error);
      }
    }
  });

  const { values, errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } =
    formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <DialogContent sx={{ pb: 0, overflowY: 'unset' }}>
          <TextField
            fullWidth
            label="Auftragsname"
            {...getFieldProps('name')}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
            sx={{ mb: 3 }}
          />

          <TextField
            fullWidth
            multiline
            maxRows={1}
            label="Anzahl Teile"
            {...getFieldProps('size')}
            error={Boolean(touched.size && errors.size)}
            helperText={touched.size && errors.size}
            sx={{ mb: 3 }}
          />

          <TextField
            fullWidth
            maxRows={1}
            label="Budget"
            {...getFieldProps('budget')}
            error={Boolean(touched.budget && errors.budget)}
            helperText={touched.budget && errors.budget}
            sx={{ mb: 3 }}
          />

          <TextField
            fullWidth
            maxRows={1}
            label="Kunde"
            {...getFieldProps('business_partner')}
            error={Boolean(touched.business_partner && errors.business_partner)}
            helperText={touched.business_partner && errors.business_partner}
            sx={{ mb: 3 }}
          />

          <DatePicker
            label="Controlled picker"
            value={dateOfDelivery}
            onChange={(newValue) => setDateOfDelivery(newValue)}
          />
        </DialogContent>

        <DialogActions>
          <Box sx={{ flexGrow: 1 }} />
          <Button type="button" variant="outlined" color="inherit" onClick={onCancel}>
            Abbrechen
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
            loadingIndicator="Loading..."
          >
            Speichern
          </LoadingButton>
        </DialogActions>
      </Form>
    </FormikProvider>
  );
}
